import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardBody,
  Heading,
  Image,
  Spinner,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Wrap,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import {
  AfterBottlingAction,
  BankAccountType,
  BottlingOrderAmount,
  Customization,
  DeliveryOccasionLocation,
  GetBottlingOrder,
  GetBottlingOrderVariables,
} from "__generated__/graphql";

const GET_BOTTLING_ORDER = gql`
  query GetBottlingOrder($orderId: Int!) {
    adminScope {
      bottlingOrders(first: 1, where: { and: { orderId: { eq: $orderId } } }) {
        nodes {
          id
          bottlingAmount
          numberOfBottles
          additionalLabelInfo
          alcoholContent
          deliveryLocation
          afterBottlingAction
          caskSignText
          bankAccountType
          clearingNumber
          bankAccountNumber
          fullName
          email
          phoneNumber
          comment

          bottleLabels {
            id
            oldBOXLabel
            customization
            text
            imageUrl
          }
          deliveryOccasion {
            id
            name
            deadline
            pickup
            internalActivityInformation
            bottlingCountries
          }
        }
      }
    }
  }
`;

const mapBottlingOrderAmount = (amount: BottlingOrderAmount) => {
  switch (amount) {
    case BottlingOrderAmount.FULL_BOTTLING:
      return "Helbuteljering";
    case BottlingOrderAmount.PART_BOTTLING:
      return "Delbuteljering";

    default:
      return "Okänd";
  }
};

const mapAfterBottlingAction = (action: AfterBottlingAction) => {
  switch (action) {
    case AfterBottlingAction.REFILL:
      return "Refill orökt destillat";
    case AfterBottlingAction.REFILL_SMOKEY:
      return "Refill rökigt destillat";
    case AfterBottlingAction.REFILL_TWO_YEAR:
      return "Refill två-årigt orökt destillat";
    case AfterBottlingAction.REFILL_TWO_YEAR_SMOKEY:
      return "Refill två-årigt rökigt destillat";
    case AfterBottlingAction.SAVE:
      return "Skicka fat till kund";
    case AfterBottlingAction.SELL:
      return "High Coast köper fatet";
    case AfterBottlingAction.SCRAP:
      return "Skrotas";

    default:
      return "Okänd";
  }
};

const mapDeliveryOccationLocation = (action: DeliveryOccasionLocation) => {
  switch (action) {
    case DeliveryOccasionLocation.DENMARK:
      return "Danmark";
    case DeliveryOccasionLocation.ESTONIA:
      return "Estland";
    case DeliveryOccasionLocation.GERMANY:
      return "Tyskland";
    case DeliveryOccasionLocation.SWEDEN:
      return "Sverige";

    default:
      return "Okänd";
  }
};

const mapBankAccountType = (action: BankAccountType) => {
  switch (action) {
    case BankAccountType.SWEDISH:
      return "Svenskt";
    case BankAccountType.INTERNATIONAL:
      return "Internationellt";

    default:
      return "Okänd";
  }
};

const mapCustomization = (action?: Customization) => {
  switch (action) {
    case Customization.FILE:
      return "Egen bild";
    case Customization.PERSONAL_TEXT:
      return "Personlig text";
    case Customization.NONE:
      return "Vanlig";

    default:
      return "Okänd";
  }
};

const BottlingOrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { loading, data } = useQuery<
    GetBottlingOrder,
    GetBottlingOrderVariables
  >(GET_BOTTLING_ORDER, {
    variables: { orderId: Number(orderId) },
  });
  const order = data?.adminScope.bottlingOrders?.nodes![0]!;

  if (loading) return <Spinner />;

  if (!order) return null;

  return (
    <>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Namn</StatLabel>
            <StatNumber fontSize="lg">{order.fullName}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>E-post</StatLabel>
            <StatNumber fontSize="lg">{order.email}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Telefonnummer</StatLabel>
            <StatNumber fontSize="lg">{order.phoneNumber}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Buteljeringsmängd</StatLabel>
            <StatNumber fontSize="lg">
              {mapBottlingOrderAmount(order.bottlingAmount)}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Antal flaskor</StatLabel>
            <StatNumber fontSize="lg">{order.numberOfBottles}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Alkoholhalt</StatLabel>
            <StatNumber fontSize="lg">{order.alcoholContent}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Efter buteljering</StatLabel>
            <StatNumber fontSize="lg">
              {mapAfterBottlingAction(order.afterBottlingAction)}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Fatskylt-text</StatLabel>
            <StatNumber fontSize="lg">{order.caskSignText}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel></StatLabel>
            <StatNumber fontSize="lg"></StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Leveransställe</StatLabel>
            <StatNumber fontSize="lg">
              {mapDeliveryOccationLocation(order.deliveryLocation)}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Leveranstillfälle</StatLabel>
            <StatNumber fontSize="lg">
              {order.deliveryOccasion?.name}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Telefonnummer</StatLabel>
            <StatNumber fontSize="lg">{order.phoneNumber}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Bankkonto-typ</StatLabel>
            <StatNumber fontSize="lg">
              {mapBankAccountType(order.bankAccountType)}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Clearingnummer</StatLabel>
            <StatNumber fontSize="lg">{order.clearingNumber}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Banknummer</StatLabel>
            <StatNumber fontSize="lg">{order.bankAccountNumber}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup mt={4} mb={2}>
          <Stat>
            <StatLabel>Övriga kommentarer</StatLabel>
            <StatNumber fontSize="md" fontWeight="normal">
              {order.comment}
            </StatNumber>
          </Stat>
        </StatGroup>
      </Box>
      <Heading size="lg" mb={2}>
        Etiketter
      </Heading>
      <Box mb={4} bg="gray.100" p={4} borderRadius={4}>
        <StatGroup mb={2}>
          <Stat>
            <StatLabel>Etikett-meddelande</StatLabel>
            <StatNumber fontSize="lg">
              {order.additionalLabelInfo || "-"}
            </StatNumber>
          </Stat>
        </StatGroup>
        <Wrap spacing={4}>
          {order.bottleLabels?.map((bl, i) => (
            <Card maxW="lg" bg="white" key={bl?.id}>
              <CardBody>
                <Stack spacing="3">
                  <Heading size="md">{`Etikett ${i + 1}`}</Heading>
                  {bl?.imageUrl && (
                    <Image
                      border="1px"
                      borderColor="gray.500"
                      src={bl?.imageUrl}
                      alt="Etikett"
                    />
                  )}
                  <Stat>
                    <StatLabel>Typ</StatLabel>
                    <StatNumber fontSize="lg">
                      {mapCustomization(bl?.customization)}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>Text</StatLabel>
                    <StatNumber fontSize="lg">{bl?.text || "-"}</StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>Använd BOX-etikett</StatLabel>
                    <StatNumber fontSize="lg">
                      {bl?.oldBOXLabel ? "Ja" : "Nej"}
                    </StatNumber>
                  </Stat>
                </Stack>
              </CardBody>
            </Card>
          ))}
        </Wrap>
      </Box>
    </>
  );
};

export default BottlingOrderDetails;
